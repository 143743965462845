import React from "react";
import { Header, LaunchText, SocialMediaIcons } from "../../components";
import XcellifyLogoPrimary from "../../assests/images/xcellify-logo-primary.png";

import FamilyImg from "../../components/familyImg/FamilyImg";
import FamilyImage from "../../assests/images/HD image-10.png";
import { iconsData } from "../../utils/constants";

const SmallScreenLayout: React.FC = () => {
  return (
    <div className="w-full h-screen bg-white relative overflow-hidden">
      {/* Background Layer */}
      <div className="absolute inset-0 bg-[#876FFD] bg-opacity-20"></div>

      {/* Header */}
      <Header leftImageUrl={XcellifyLogoPrimary} />

      {/* Content Section */}
      <div className="flex flex-col items-center justify-center h-full px-4">
        {/* Family Image */}
        <div className="mt-8 sm:mt-0 mb-6 sm:mb-12">
          <FamilyImg ImageUrl={FamilyImage} />
        </div>

        {/* Launch Text */}
        <div className="mt-6 sm:mt-12 px-4">
          <LaunchText
            text1="A Whole New World of Possibilities for Students!"
            text2="Coming"
            text3=" Soon..."
          />
        </div>

        {/* Social Media Icons */}
        <div className="relative top-24">
          {" "}
          {/* Add relative positioning and move down */}
          <SocialMediaIcons icons={iconsData} />
        </div>
      </div>
    </div>
  );
};

export default SmallScreenLayout;
