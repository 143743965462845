import React from "react";

interface FamilyImgProps {
  ImageUrl: string;
}

const FamilyImg: React.FC<FamilyImgProps> = ({ ImageUrl }) => {
  return (
    <div className="fixed top-40 right-0 transform -translate-y-1/3 z-10">
      <img src={ImageUrl} alt="Family Image" className="object-contain" />
    </div>
  );
};

export default FamilyImg;
