import React from "react";

interface LaunchTextProps {
  text1: string;
  text2: string;
  text3: string;
}

const LaunchText: React.FC<LaunchTextProps> = ({ text1, text2, text3 }) => {
  return (
    <div className="flex flex-col items-start p-4 sm:p-6 mt-12 xs:mt-14 sm:mt-16">
      {/* Main text */}
      <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-medium text-primaryText text-left">
        {text1}
      </p>

      {/* Highlighted text */}
      <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-highlightText pt-3 sm:pt-4 md:pt-5 leading-snug">
        <span className="text-highlightText">{text2}</span>
        {text3}
      </h1>
    </div>
  );
};

export default LaunchText;
