import React from "react";
import { useMediaQuery } from "react-responsive";
import SmallScreenLayout from "./SmallScreenLayout";
import DefaultLayout from "./DefaultLayout";

const LaunchScreen: React.FC = () => {
  // Detect screen size
  const isSmallScreen = useMediaQuery({ query: "(max-width: 640px)" });

  // Render layout based on screen size
  if (isSmallScreen) {
    return <SmallScreenLayout />;
  }

  return <DefaultLayout />;
};

export default LaunchScreen;
