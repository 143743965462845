import React from "react";

interface HeaderProps {
  leftImageUrl: string;
}

const Header: React.FC<HeaderProps> = ({ leftImageUrl }) => {
  return (
    <header className="fixed top-0 left-0 w-full z-50">
      <div className="flex items-center justify-between w-full px-4 py-2 md:px-8 md:py-4">
        <img
          src={leftImageUrl}
          alt="Left Logo"
          className="w-24 h-24 sm:w-28 sm:h-28 md:w-36 md:h-36 lg:w-44 lg:h-44 rounded-full object-contain cursor-pointer"
        />
      </div>
    </header>
  );
};

export default Header;
