import React from "react";
import { Header, LaunchText, SocialMediaIcons } from "../../components";
import XcellifyLogoPrimary from "../../assests/images/xcellify-logo-primary.png";
import backgroundImage from "../../assests/images/background7.png";
import { iconsData } from "../../utils/constants";
import BoxCard from "../../components/boxCard/BoxCard";
import BoxCardImg from "../../assests/images/BoxCardImg.png";
import CircleCardImg from "../../assests/images/CircleImg.png";
import CircleCard from "../../components/circleCard/CircleCard";
import FamilyImage from "../../assests/images/HD image-10.png";
import FamilyImg from "../../components/familyImg/FamilyImg";

const DefaultLayout: React.FC = () => {
  return (
    <div className="w-full h-screen bg-white relative overflow-hidden">
      <div className="absolute inset-0 bg-[#876FFD] bg-opacity-20"></div>
      <Header leftImageUrl={XcellifyLogoPrimary} />
      <div className="flex flex-col pt-20">
        <BoxCard ImageUrl={BoxCardImg} />
      </div>
      <FamilyImg ImageUrl={FamilyImage} />
      <CircleCard ImageUrl={CircleCardImg} />
      <div className="fixed bottom-0 left-10 z-20">
        <LaunchText
          text1="A Whole New World of Possibilities for Students!"
          text2="Coming"
          text3=" Soon..."
        />
        <SocialMediaIcons icons={iconsData} />
      </div>
    </div>
  );
};

export default DefaultLayout;
