import React from "react";

interface CircleCardProps {
  ImageUrl: string;
}

const CircleCard: React.FC<CircleCardProps> = ({ ImageUrl }) => {
  return (
    <div className="hidden lg:flex fixed bottom-0 right-0 m-0">
      <img
        src={ImageUrl}
        alt="circle card"
        className="w-32 sm:w-36 md:w-40 lg:w-48 xl:w-60 h-auto object-contain"
      />
    </div>
  );
};

export default CircleCard;
