// import React from "react";
// import { SocialMediaIconsProps } from "../../utils/constants";

// const SocialMediaIcons: React.FC<SocialMediaIconsProps> = ({ icons }) => {
//   return (
//     <div className="flex space-x-4 sm:space-x-6 m-6 sm:m-10">
//       {icons.map((icon, index) => (
//         <a
//           key={index}
//           href={icon.link}
//           target="_blank"
//           rel="noopener noreferrer"
//           aria-label={icon.platform}
//           className="hover:opacity-75 transition-opacity"
//         >
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             className="h-3 w-3 sm:h-5 sm:w-5 md:h-7 md:w-7"
//             fill="currentColor"
//             style={{ color: icon.color }}
//             viewBox="0 0 24 24"
//           >
//             <path d={icon.svgPath} />
//           </svg>
//         </a>
//       ))}
//     </div>
//   );
// };

// export default SocialMediaIcons;

// with img tag

import React from "react";
import { SocialMediaIconsProps } from "../../utils/constants";
import RectangleBackground from "../../assests/images/Social-Rectangle.png";

const SocialMediaIcons: React.FC<SocialMediaIconsProps> = ({ icons }) => {
  return (
    <div className="relative flex m-6 sm:m-10">
      <div className="relative z-10 flex space-x-5 sm:space-x-10">
        {icons.map((icon, index) => (
          <a
            key={index}
            href={icon.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={icon.platform}
            className="hover:opacity-75 transition-opacity"
          >
            <img
              src={icon.iconUrl}
              alt={`${icon.platform} icon`}
              // className="h-6 w-6 sm:h-8 sm:w-8"
              style={{ color: icon.color }}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default SocialMediaIcons;
