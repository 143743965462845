import React from "react";

interface BoxCardProps {
  ImageUrl: string;
}

const BoxCard: React.FC<BoxCardProps> = ({ ImageUrl }) => {
  return (
    <div className="hidden lg:flex fixed">
      <img
        src={ImageUrl}
        alt="box card"
        className="w-32 sm:w-36 md:w-30 lg:w-30 xl:w-40 h-auto object-contain"
      />
    </div>
  );
};

export default BoxCard;
